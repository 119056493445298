import React from "react"
import { Form, FormGroup, Input, Button, InputGroup } from "reactstrap"
import { Search } from "react-feather"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import { useState, useEffect } from "react"

const NewsCards = ({ news }) => {
  const [query, setQuery] = useState("")
  const [filterParam, setFilterParam] = useState(["All"])

  function search(news) {
    return news.filter((item) => {
      if (filterParam == item.type) {
        return (
          item.title.toString().toLowerCase().indexOf(query.toLowerCase()) > -1
        )
      } else if (filterParam == "All") {
        return (
          item.title.toString().toLowerCase().indexOf(query.toLowerCase()) > -1
        )
      }
    })
  }

  return (
    <section style={{ width: "90%", margin: "auto" }}>
      <Form className="mt-3">
        <FormGroup className="d-flex ms-auto app-form">
          <InputGroup>
            <Input
              type="select"
              className="me-3"
              onChange={(e) => {
                setFilterParam(e.target.value)
              }}
            >
              <option hidden>Categories</option>
              <option value="All">All</option>
              <option value="News">News</option>
              <option value="Events">Events</option>
            </Input>
          </InputGroup>
          <InputGroup>
            <Input
              type="text"
              placeholder="Search.."
              className="p-1"
              onChange={(e) => setQuery(e.target.value)}
            />
            <Button
              style={{ backgroundColor: "#00623D" }}
              onClick={(e) => e.preventDefault()}
            >
              <Search size={16} />
            </Button>
          </InputGroup>
        </FormGroup>
      </Form>
      <div className="news-card">
        {search(news).map((content) => (
          <div className="news-events">
            <div className="news-image">
              <GatsbyImage image={getImage(content.thumbnail)} />
            </div>
            <div className="news-content">
              <h4 className="news-date">{content.date}</h4>
              <h4 className="news-title">{content.title}</h4>
              <Link to={`/News/${content.slug}`}>
                <button className="news-button">Read More</button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default NewsCards
